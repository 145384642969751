@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

body {
  font-family: "Rubik", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
}

/* new-css */

.sidebar-nav .nav-link {
  font-size: 14px;
}
.sidebar-nav .nav-icon {
  flex: 0 0 2.5rem;
}

.form-group label {
  font-weight: 500;
  font-size: 14px;
}

.form-group .form-control {
  padding: 10px;
  height: 50px;
}

.card-header h4 {
  margin-bottom: 0;
  font-size: 20px;
}

.divider_line {
  border-color: #6a6a6a;
  opacity: 1;
  background: transparent;
}

.divder_title h3 {
  font-size: 18px;
  text-transform: capitalize;
}
.payment_mode input[type="checkbox"] {
  width: 21px;
  height: 21px;
  vertical-align: middle;
}

.form-check-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.verticle-align a {
  vertical-align: middle;
  display: inline-block;
  margin: 0 5px;
}

.table_data_middle td {
  vertical-align: middle !important;
}

/* end-new-css */

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  left: -10000px;
  overflow: hidden;
}

.control,
fieldset {
  margin: 6px 0;
}

.required {
  color: rgb(250, 37, 37);
}

/**===============new-designing================**/
a {
  color: #747474;
  text-decoration: none;
}

a:hover {
  color: #efa847;
  text-decoration: none !important;
}
.login-overlay:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #120b0085;
}

.card_primary_login {
  display: none;
}

.login-page-wrapper {
  position: relative;
  z-index: 2;
}

.login-logo {
  text-align: center;
}

.login-logo img {
  max-width: 150px;
}

.login-logo {
  margin: 0 0 24px 0;
}

.login-logo .form-control {
  padding: 19px;
}

.login_form .form-control {
  height: auto;
  padding: 15px;
  background: #fff;
  border: 1px solid #dfdfdf;
}

.login_form .input-group .input-group-text {
  background: #fff;
  padding: 10px 14px;
  color: #000;
  border: 1px solid #dfdfdf;
}

.theme-btn {
  background: #faa41a;
  padding: 12px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}
.theme-btn:hover,
.btn-primary:hover {
  background: #faa41a !important;
  border-color: #faa41a !important;
}

.btn-primary {
  background: #faa41a;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.login-page-wrapper .card-group {
  max-width: 460px;
  margin: auto;
}

.theme-btn:after,
.btn-primary:after {
  content: "";
  position: absolute;
  top: 0;
  background: linear-gradient(45deg, #ffffff2e, transparent);
  width: 100%;
  height: 100%;
  left: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transform: translateX(-100%);
}

.theme-btn:hover:after,
.btn-primary:hover:after {
  transform: translateX(100%);
}

.sidebar {
  background: #fff;
  box-shadow: 2px 0 20px rgb(0 0 0 / 10%);
}

.sidebar-nav .nav-link {
  color: #666;
}

.sidebar-nav .nav-icon {
  color: #303030;
}

.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-link.active {
  background: #efa847;
  color: #000;
}
.btn-primary {
  background: #efa847;
  color: #000;
  border-color: #efa847;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background: #efa847;
  border-color: #ce8b2c;
}

.btn-outline-secondary {
  color: rgb(95, 95, 95);
  border-color: #ce8b2c;
}
.table th,
.table td {
  font-size: 14px;
}
.table thead th.cursor_pointer {
  color: #efa847 !important;
}

.page-item.active .page-link {
  background: #efa847 !important;
  border-color: #ddb57f !important;
}

.sidebar-nav .nav-link:hover .nav-icon,
.sidebar-nav .nav-link.active .nav-icon {
  color: #000;
}
.footer {
  font-size: 16px;
}
.sidebar-brand {
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 7px 15px;
  justify-content: flex-start;
}

.sidebar-brand img {
  max-width: 64px;
  height: auto;
}

.sidebar-toggler {
  background: #3c3c3c;
}

.breadcrumb {
  padding: 0;
  background: transparent;
}

.breadcrumb a {
  color: #000;
}

.breadcrumb-item.active {
  color: #adadad;
}

.form-select,
.form-control {
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #dbdbdb;
  height: auto;
}
.form-select:focus,
.form-control:focus {
  box-shadow: none;
  border-color: #ce8b2c;
}
.page-link,
li.page-item.disabled a {
  color: #333 !important;
  font-size: 14px;
}

.header-divider {
  border-top-color: #f1f1f1;
}

/* .sidebar-nav{margin-top:32px;}  */

.white-card,
.achiv_card .card {
  background: #fff !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
}
.achiv_card .card {
  padding: 32px 24px;
}

.achiv_card .card .fw-semibold {
  border: 1px solid #ede5cf;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px !important;
  height: 64px !important;
  background: #fff8f0;
  color: #000;
  font-size: 24px !important;
  font-weight: normal !important;
}

.achiv_card .card .card-body > div {
  display: flex;
  align-items: center;
  padding: 0;
}

.achiv_card .card .card-body {
  padding: 0;
}

.achiv_card .card .fw-semibold + div {
  color: #262626;
  padding-left: 16px;
  font-weight: 500;
}

.theme-table.table td {
  background: #fff;
}

.theme-table.table th {
  background: #f1f1f1;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 16px;
  padding: 7px 15px;
}

form .btn-danger {
  background: #f1f1f1;
  color: #333;
  border-color: #e1e1e1;
}
button .btn-danger {
  background: #c40303;
  color: rgb(250, 250, 250);
  border-color: #faa41a;
}

form .btn-danger:hover {
  background: #e7e7e7;
  color: #333;
  border-color: #dddd;
}

.select_picker_custome {
  width: 100% !important;
  padding: 0;
}

.select_picker_custome .btn {
  font-size: 14px;
  padding: 10px 15px;
  border: none;
  height: auto;
  background: transparent;
}

.child_category {
  padding-left: 50px;
}

.custom_flatpickr {
  background-color: white !important;
}

.img-wraps {
  position: relative;
  display: inline-block;

  font-size: 0;
}
.img-wraps .closes {
  position: absolute;
  top: 5px;
  right: 8px;
  z-index: 100;
  background-color: #fff;
  padding: 4px 3px;

  color: #000;
  font-weight: bold;
  cursor: pointer;

  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
  border: 1px solid red;
}
.img-wraps:hover .closes {
  opacity: 1;
}

/* Side Bar */

.sidebar-nav .nav-group.show > a {
  background: #efa847;
  color: #000 !important;
}


/* Category Arrange rows width */

.draggable_row_width {
  width: 1000px !important;
}