// Here you can add other styles

label {
  margin-bottom: 10px;
}

.form-control {
  margin-bottom: 12px;
}

.select_image_category {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 1px solid #34272736;
  margin-left: 34px;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}

.breadcrumb-item a {
  text-decoration: none;
}

.footer a {
  text-decoration: none;
}

.choose_image {
  justify-content: space-between;
}

.login_form {
  & .form-control {
    margin-bottom: 0px;
  }
}

.product_slider_category div:focus-visible {
  border: none;
  outline: none;
}

.product_slider_category .slick-slide img {
  width: 300px;
  height: 153px;
  object-fit: cover;
  margin: 0 auto;
  border: none;
}

.product_slider_category {
  padding: 5px 0px;
}

.category_selecgt_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acceserry_mobile .react-dropdown-select-content span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
}

.acceserry_mobile1 .react-dropdown-select-content span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}

.category_selecgt_image {
  position: relative;
  width: 145px;
  height: 145px;
  margin: 0 auto;
  border: 1px solid #a7bcbc;
}

.preview .btn {
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  /* display: flex; */
  padding: 0px;
  font-size: 12px;
}

.category_first {
  font-size: smaller;
}

.preview .border-1 {
  border: 1px solid #00000047;
  padding: 13px 20px 12px 9px;
  position: relative;
  border-color: #a7bcbc !important;
}

@media (max-width: 1450px) {
  .category_selecgt_image {
    width: 108px;
    height: 108px;
  }

  .home_page_banner_sec .banner_short {
    height: 140px !important;
  }

  .acceserry_mobile1 .react-dropdown-select-content span {
    max-width: 8ch;
  }

  .preview .form-control {
    font-size: 12px;
  }

  .home_page_banner_sec .banner_home_select .form-select {
    font-size: 12px;
  }
}

@media (max-width: 1199.98px) {
  .category_selecgt_image {
    width: 94px;
    height: 94px;
  }
}

@media (max-width: 767.98px) {
  .choose_image {
    flex-direction: column;
  }

  .category_selecgt_image {
    margin-bottom: 11px;
  }

  .acceserry_mobile .react-dropdown-select-content span {
    max-width: 20ch;
  }

  .select_image_category {
    width: 75px;
    height: 75px;
    margin-left: 10px;
    margin-bottom: 12px;
  }

  .card_primary_login {
    width: 100% !important;
  }
}

.avatar-img {
  height: 100% !important;
}

// 30/6/21 //

.home_page_banner_sec .banner_short {
  height: 200px;
}

.home_page_banner_sec .banner_short img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home_page_banner_sec {
  position: relative;
  cursor: move;
}

.home_page_banner_sec .banner_home_select .form-select:focus {
  box-shadow: none;
}

.home_page_banner_sec .btn {
  position: absolute;
  right: -9px;
  top: -9px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  /* display: flex; */
  padding: 0px;
  font-size: 12px;
}

.home_page_banner_sec .banner_home_select .form-select {
  margin-bottom: 8px;
}

.home_page_banner_sec .banner_home_select {
  margin-top: 11px;
  padding: 11px 10px;
  margin-bottom: 20px;
}

.home_page_banner_sec {
  border: 1px solid #1508085e;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.cursor_pointer {
  cursor: pointer;
  color: blue !important;
}

// 30/6/21//

.react-datepicker-wrapper {
  display: block !important;
}

.align-item-center {
  position: absolute;
  left: 50%;
  top: 50%;
}

ul li.rearrrange {
  display: -webkit-inline-box;
  background: #c8d5c2;
  height: 40px;
  border: 1px solid white;
  border-radius: 7px;
  margin: 0;
  width: 50%;
  padding: 5px 14px;
  color: #000 !important;
  cursor: pointer;
}

.popular_city_list {
  padding: 0;
  margin: 0;
}

.popular_city_list li {
  list-style-type: none;
}

.popular_city_list li {
  list-style-type: none;
  margin-bottom: 7px;
  border-bottom: 1px solid #1a10101f;
  padding-bottom: 13px;
}

.popular_city_list {
  margin: 0;
  border: 1px solid #00000036;
  padding: 15px 13px;
  border-radius: 7px;
  overflow-y: auto;
  height: 415px;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: #fff;
}

// 22/11/21//

.react-dropdown-select-clear {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-64%);
}

.react-dropdown-select-dropdown-handle {
  margin: 0;
}

.css-1aarvou-DropdownHandleComponent {
  margin: 0 !important;
}

.cross-icon {
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 9 !important;
  padding: 0 !important;
}

.plus_fixed .btn svg {
  border: 2px solid #dc3545;
  border-radius: 100%;
}

.cross-icon i {
  color: #fff !important;
}

.product_mg_table thead tr {
  background: #e9ecef;
}

form.banner_form {
  width: 100%;
}

//22/22/21//

.li-anchor {
  position: absolute;
  right: 6px;
  font-size: 11px;
  background: red;
  width: 20px;
  height: 20px;
  /* dominant-baseline: text-after-edge; */
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  top: 6px;
  cursor: pointer;
}

.li-anchor:hover {
  color: #fff;
  text-decoration: none;
}

.ul-anchor li figcaption {
  height: 100px;
}

.ul-anchor li figcaption img {
  height: 100%;
  object-fit: cover;
}

.ul-anchor li {
  max-width: 33.33%;
  flex: 0 0 33.33%;
}

.sidebar:not(.sidebar-end)~* {
  --cui-sidebar-occupy-start: 16rem;
}

.add-form-check input {
  margin-right: 9px;
  width: 55px;
}

.add-form-check .btn {
  padding: 3px 10px;
}

// iframe {
//   pointer-events: none;
// }

.wh-50px {
  width: 50px;
  height: 50px;
}





.loder-box {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.loder-box:after {
  content: "";
  position: fixed;
  background: #0000003d;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loder-box .spinner-border {
  position: relative;
  z-index: 9999;
}