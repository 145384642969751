.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);
}

.PhoneInputInput, .PhoneInputInput:focus-visible, .PhoneInput:focus{
  border: none;
}

.table-record-sec th, .table-record-sec td {
  vertical-align: middle;
}

.heightBox{height: 100px}

.all-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.all-btn button {
  margin-bottom: 10px;
}


.pin2 {
  position: absolute;
  top: 40%;
  left: 50%;
  /* margin-left: 115px; */
  
  border-radius: 50%;
  border: 8px solid rgb(255, 14, 14);
  width: 20px;
  height: 20px;
}

.pin2::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -27px;
  left: -8px;
  border: 10px solid transparent;
  border-top: 17px solid rgb(255, 14, 14);
}









.open_sidebar {
  margin-left: 0!important;
}

.close_sidebar {
  margin-left: -16rem!important;
}.close_sidebar + .wrapper {
  padding-left: 0;
}





@media screen and (max-width: 991px) { 

.sidebar-fixed {
  margin-left: -270px!important;
}

/* .sidebar-fixed.open_sidebar{ margin-left: 0px!important;} */


}